/*Login Styles*/
.login-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.login-wrapper .login-form-wrapper {
  padding: 24px;
  width: min(90%, 480px);
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 8px;

  /* transform: translate3D(0,-1px,0) scale(1.02);
  transition: all .4s ease; */
}
.login-wrapper .login-form-wrapper .login-form .login-btn {
  margin-top: 8px;
}

.login-wrapper .login-form-wrapper .header {
  margin-bottom: 20px;
}
.login-wrapper .login-form-wrapper .body {
  margin-top: 20px;
  text-align: center;

  #btn-signin {
    width: 79px;
    height: 32px;
    border-radius: 2px;
    line-height: 22px;
  }
}

.callback-wrapper {
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
