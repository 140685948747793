.ant-layout-sider .ant-layout-sider-children {
  background: url('../../../assets/sider-frame.png');
  background-repeat: no-repeat;
  background-size: cover;
}

.gx-icon-btn.icon .anticon {
  color: #ffffff;
}

.ant-layout-sider-children .gx-sidebar-content {
  border-right: none;
  background: transparent;
}

.ant-layout-sider-children .gx-sidebar-content .ant-menu {
  background: transparent;
}

.ant-layout-sider-children .gx-sidebar-content .ant-menu .ant-menu-item {
  width: 100%;
  margin: 0px;
  height: 40px;
  color: #bfbfbf;
  padding: 0px 16px 0px 20px !important;
}

.ant-layout-sider-children .gx-sidebar-content .ant-menu .ant-menu-item span {
  font-size: 14px;
}

.ant-layout-sider-children .gx-sidebar-content .ant-menu .ant-menu-item:after {
  border-right: 3px solid #001220;
}

.ant-layout-sider-children
  .gx-sidebar-content
  .ant-menu
  .ant-menu-item.ant-menu-item-selected {
  color: #001220;
  background-color: #ffffff;
}

.ant-layout-sider-collapsed
  .ant-layout-sider-children
  .gx-sidebar-content
  .ant-menu
  .ant-menu-item {
  display: flex;
  justify-content: center;
}

.sider-footer-content {
  width: 100%;
  height: 75px;
  position: absolute;
  bottom: 0;
  border-top: 1px solid #595959;
}
