@import '../../styles/variables.less';

.import-users-container {
  .title-container {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    height: 27px;
    line-height: 27px;
  }

  .primay-link {
    color: #038fde;
  }
}

.import-users-modal {
  .ant-modal-body {
    padding: 16px 24px;
  }

  .ant-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    height: 22px;
    line-height: 22px;
    color: @action-edit-btn-color;
  }

  .upload-logo {
    margin-bottom: 16px;

    &.uploaded-file {
      .ant-upload-list {
        background-color: '#ffffff';
      }

      .ant-upload {
        display: none;
      }

      .ant-upload-list {
        border: 1px dashed #d9d9d9;
        width: 100%;
        padding: 16px;
        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;
        margin-bottom: 16px;
      }

      .ant-upload-list-picture-card-container {
        max-width: 200px;
        max-height: 192px;
        border: none;
        margin: 0px;
      }
    }
  }

  .import-user-footer {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .sample-content {
      display: flex;
      align-items: center;

      .link-btn {
        margin-left: 6px;
        cursor: pointer;
        color: #038fde;
        text-decoration: underline;
      }
    }

    button {
      margin-bottom: 0px;
    }
  }
}
