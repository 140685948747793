@import '../../styles/variables.less';

.dynamic-pages-container {
  .title-container {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    height: 51px;
    line-height: 51px;
  }

  .edit-action-btn-color {
    color: @action-edit-btn-color;
  }
}
