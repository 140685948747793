#root {
  height: 100vh;
}

.header-notification {
  display: flex;
  align-items: center;

  & > * {
    margin-right: 20px;
  }

  & > .notification-bell {
    font-size: 20px;
    cursor: pointer;
  }
}

.gx-sidebar-content {
  & > .ant-menu {
    & > .ant-menu-item {
      margin: 12px 0;

      & > .menu-icons {
        font-size: 20px;
      }

      & > span {
        font-size: 18px;
      }
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .anticon {
      font-size: 20px;
      padding: 0 4px;
    }
  }
}

.ant-table {
  & .ant-table-tbody {
    & .ant-btn {
      margin: 0;
      padding: 0;
      height: auto;
    }
  }
}

.event-header-image {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.event-header-image.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.event-form {
  .ant-form-item-label {
    padding: 0;
    margin: 0;
  }
}

.ant-message-custom-content {
  display: flex;
  align-items: center;

  > .ant-message-custom-content .ant-message-info {
    height: 36px;
    display: flex;
    align-items: center;
  }
}

.icon-btn {
  display: flex;
  align-items: center;
}

.upload-image-container {
  & .ant-upload-select-picture-card {
    width: 100%;
  }
}

.upload-image-container.ant-upload-picture-card-wrapper {
  padding: 0 16px;
}

.custom-icons-list {
  margin-right: 10px;
}

.ant-table-column-sorters {
  padding: 0;
}

.login-content {
  padding: 35px;
  width: 100%;
}

.ant-input-prefix {
  display: flex;
  align-items: center;
  justify-content: center;
}

.reset-password {
  text-align: center;
}

.ant-btn-sm {
  line-height: 0;
}

.attendee-details {
  .ant-form-item {
    min-width: 215px;
    margin-right: 16px;
  }
}

.rsvp-modal {
  .ant-modal-footer {
    div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }
  }
}

.ant-input-suffix {
  display: flex;
  align-items: center;
}

.option-input {
  border: unset;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;

  &:focus {
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
    box-shadow: unset;
  }

  &:hover {
    border-color: transparent;
    border-right-width: 0;
    border: unset;
    border-bottom: 1px solid #d9d9d9;
    border-radius: 0;
  }
}

.option-input.ant-input-affix-wrapper-focused {
  box-shadow: unset;
}

.pointer {
  cursor: pointer;
}

.donation-list {
  overflow: auto;
}

.donation-card {
  border: 1px solid #e5e5e5;
  padding: 14px 32px;
  border-radius: 4px;
  margin-right: 24px;
  cursor: pointer;
  cursor: pointer;
  min-width: 200px;
  text-align: center;
  min-height: 100px;
  margin-bottom: 12px;
}

.donation-card.active {
  background-color: #efefef;
}

.ministry-modal {
  height: 500px;

  .ant-modal-body {
    padding: 0;
  }

  object {
    min-height: 500px;
  }
}

.dialog {
  .ant-modal-footer {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    > button:first-child {
      margin-right: 0px;
    }
  }
}

.action-icons {
  display: flex;
  align-items: center;

  button:first-child {
    margin-right: 12px;
  }

  > button {
    margin-bottom: 0;
  }
}

.img-icon {
  width: 43px;
  height: 21.5px;
}

.pr-10 {
  padding-right: 10px;
}

.select-group {
  padding: 0 0 8px;
}

.group-img-div {
  width: 70%;
  position: relative;
  .input-file {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .img-tag {
    height: 91px;
  }
  .upload-img {
    height: 91px;
    width: 180px;
    border: 1px dotted black;
    .text {
      padding-top: 20%;
      padding-bottom: 20%;
      text-align: center;
    }
  }
}

.group-img-table {
  width: 80px;
  height: 30px;
}

.ant-input-number {
  min-width: 100%;
}

.pagination {
  padding-top: 10px;
  text-align: right;
}

.header-content {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  .header-filters {
    text-align: right;
    display: flex;
  }
}

.group-image-title {
  text-align: end;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}

.edit-action-btn-color {
  color: @action-edit-btn-color;
}

.full-width {
  width: 100%;
}
