@import '../../styles/variables.less';

.users-container {
  .title-container {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    height: 27px;
    line-height: 27px;
  }

  .edit-action-btn-color {
    color: @action-edit-btn-color;
  }

  .ant-tag {
    border-radius: 2px;
  }
}

.users-modal {
  .ant-modal-body {
    padding: 12px 24px;

    .ant-form-item {
      margin-bottom: 12px;
    }
  }

  .ant-modal-title {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    height: 22px;
    line-height: 22px;
    color: @action-edit-btn-color;
  }

  .select-group,
  .ant-form-item-label > label {
    color: @action-edit-btn-color;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
  }

  .add-group {
    text-decoration: underline;
    color: @action-edit-btn-color;
  }

  .ant-form-vertical .ant-form-item-label,
  .ant-col-24.ant-form-item-label,
  .ant-col-xl-24.ant-form-item-label {
    padding: 0 0 4px;
  }

  .ant-space {
    .ant-space-item {
      .anticon-delete {
        color: #f5222d;
      }
    }
  }
}
